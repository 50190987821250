<template>
  <AsfList role="none">
    <AsfMegaMenuItem
      v-for="(item, idx) in menuItems"
      :key="item.id"
      :ref="setMenuItemRef(item)"
      :category="item"
      :class="{
        'm-level-1': level === 1,
        'm-level-2': level === 2,
        'm-level-3': level === 3,
        'm-view-all': menuItems.length - 1 === idx
      }"
      class="asf-mega-menu__column-item"
    >
      <template v-if="item.items?.length" #submenu="{ attrHidden }">
        <AsfMegaMenuColumn
          :items="item.items"
          :parent-category="item"
          :level="nextLevel"
          :aria-hidden="attrHidden"
          :aria-label="item.label"
          class="asf-mega-menu__column"
          role="menu"
        />
      </template>
    </AsfMegaMenuItem>
  </AsfList>
</template>

<script lang="ts">
import { getElementRefByID } from '../MegaMenu.utils'
import type { AsfMegaMenuColumnProps } from '@ui/types'
import type { AgnosticTaxonomy } from 'shared/types'

export default defineComponent({
  name: 'AsfMegaMenuColumn',
  props: {
    items: { type: Array as PropType<AsfMegaMenuColumnProps['items']>, required: true },
    level: { type: Number as PropType<AsfMegaMenuColumnProps['level']>, required: true },
    parentCategory: { type: Object as PropType<AsfMegaMenuColumnProps['parentCategory']>, required: true }
  },
  setup(props: AsfMegaMenuColumnProps) {
    const instance = useCurrentInstance()
    const { t } = useI18n()
    const nextLevel = ref(props.level + 1)

    const setMenuItemRef = (category: AgnosticTaxonomy) => `${category.id}-${props.parentCategory.id}`
    const getMenuItemRefById = (id: string) => `${id}-${props.parentCategory.id}`
    const setFocusToMenuItem = (menuItemId: string) => {
      const currentRef = getElementRefByID(getMenuItemRefById(menuItemId), instance)
      currentRef?.setFocusToElement()
    }

    onMounted(() => {
      EventBus.on('menuitem:focus', setFocusToMenuItem)
    })
    onBeforeUnmount(() => {
      EventBus.off('menuitem:focus', setFocusToMenuItem)
    })

    const viewAllLink = createViewAllLink(props.parentCategory, t('global.viewAll').toString())
    const menuItems = [...props.items, viewAllLink]
    return { nextLevel, setMenuItemRef, setFocusToMenuItem, menuItems }
  }
})
</script>
