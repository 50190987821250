<template>
  <li
    v-bind="$attrs"
    :id="value.toString()"
    role="option"
    class="asf-dropdown__option"
    tabindex="-1"
    :class="{ 'is-active': selectedValue === value }"
    :aria-selected="ariaSelected"
  >
    <slot />
  </li>
</template>
<script lang="ts" setup>
import type { AsfDropdownOption } from '@ui/types'
const props = defineProps<{
  selectedValue: AsfDropdownOption['value']
  value: AsfDropdownOption['value']
}>()
const ariaSelected = computed(() => props.selectedValue === props.value)
</script>
