<template>
  <div id="pageLayout" class="asf-page" ref="pageLayoutRef">
    <NuxtLoadingIndicator />
    <AsfSkipToContent anchor="#pageContent" :content="$t('content.skip')" focus-first />
    <AsfSkipToContent anchor="#footer" :content="$t('footer.skip')" focus-first />

    <AsfAlert alignment="center" appearance="error" class="unsupported-alert js-unsupported-browser is-hidden">
      {{ $t('header.error.unsupportedBrowser') }}
    </AsfAlert>
    <ClientOnly>
      <AsfAlert alignment="center" appearance="error" v-if="isCookiesError">
        <span class="asf-cookies-error-message">
          {{ $t('header.error.cookiesMessage', [$t('general.brandName')]) }}
          <AsfLink link="/help-center/customer-service/privacy-policy" class="asf-link-primary">
            {{ $t('header.error.cookiesPolicy') }}
          </AsfLink>
        </span>
      </AsfAlert>
    </ClientOnly>
    <LazyHydrationWrapper :on-interaction="'never'">
      <component :is="'noscript'">
        <AsfAlert alignment="center" appearance="error">
          {{ $t('header.error.jsDisabled') }}
        </AsfAlert>
      </component>
    </LazyHydrationWrapper>

    <AppSearch />
    <AppHeader v-bind="config?.globalHeader" />

    <div id="pageContent" class="asf-page-content">
      <slot />
    </div>

    <RenderCacheable :max-age="300" :cache-key="locale">
      <AppFooter v-bind="config?.globalFooter" />
    </RenderCacheable>
    <AsfNotificationManager>
      <template v-slot="{ type, message, dismiss, displayTime }">
        <AsfNotification :appearance="type" :display-time="displayTime" @notification:hide="dismiss">
          {{ message }}
        </AsfNotification>
      </template>
    </AsfNotificationManager>
    <AsfCookiesNotice
      v-if="genConfigCookieEnabled"
      :visible="isCookiesNoticeEnabled"
      :link-text="$t('loginPage.privacyPolicy')"
      path="/help-center/customer-service/privacy-policy"
      @confirmed:cookie-notice="handleConfirmNotice"
      @confirmed:cookie-notice-no-tracking="handleConfirmNoticeNoTracking"
    >
      <span>{{ $t('cookiesNotice.description') }}</span>
    </AsfCookiesNotice>
    <div class="asf-highlighter" ref="highlighterRef" />
    <div id="agreement-modal-placeholder" />
  </div>
</template>

<script lang="ts" setup>
const contentKey = 'site-config'
const isCookiesError = ref(false)
const isCookiesNoticeEnabled = ref(false)
const { $preferences } = useNuxtApp()
const { locale } = useI18n()
const { searchContent } = useContent()
const { data: content } = await useAsyncData(contentKey, async () => {
  return await searchContent({ keys: [contentKey] })
})
const config = computed(() => content.value?.[contentKey]?.at(0))
const pageLayoutRef = ref(null)
const highlighterRef = ref(null)
const genConfigCookieEnabled = ref($preferences.cookiesNoticeEnabled)

useFocusHighlighter(pageLayoutRef, highlighterRef)

onMounted(() => {
  if (!navigator.cookieEnabled) {
    isCookiesError.value = true
  }
  isCookiesNoticeEnabled.value = !(
    getLocalStorageItem('cookieNoticedClosedAll') || getLocalStorageItem('cookieNoticedClosedNoTracking')
  )
})
const handleConfirmNotice = () => {
  setLocalStorageItem('cookieNoticedClosedAll', 'true')
  isCookiesNoticeEnabled.value = false
}
const handleConfirmNoticeNoTracking = () => {
  setLocalStorageItem('cookieNoticedClosedNoTracking', 'true')
  isCookiesNoticeEnabled.value = false
}
</script>
