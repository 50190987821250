<template>
  <footer class="asf-footer" v-e2e="'global-footer'">
    <div class="asf-footer__top" v-if="$preferences.newsletterSignUpEnabled || $slots['content-socials']">
      <AsfWrapper class="asf-footer__top-inner">
        <AsfFooterNewsletter v-if="$preferences.newsletterSignUpEnabled" :content="newsletter" @onSubmit="onSubmit" />

        <div v-if="$slots['content-socials']" class="asf-social-network">
          <slot name="content-socials" :class-names="['asf-social-network__set-of-icons']" />
        </div>
      </AsfWrapper>
    </div>
    <div class="asf-footer__bottom">
      <AsfWrapper v-if="$slots['content-benefits']">
        <div class="asf-footer-info">
          <slot name="content-benefits" :class-names="['asf-footer-info__inner']" />
        </div>
      </AsfWrapper>

      <slot name="content-navigation" />

      <AsfWrapper>
        <div v-if="$slots['content-features']" class="asf-footer-payments">
          <AsfSecureIcon :title="$t('footer.secureShoppingIconText')" class="asf-footer__secure-icon" />
          <slot name="content-features" />
        </div>
        <span class="asf-footer__copy">
          {{ $t('global.copyright', { copy: '&copy;', year: new Date().getFullYear().toString() }) }}
        </span>
      </AsfWrapper>
    </div>
    <AsfBackToTop />
  </footer>
</template>
<script lang="ts">
import type { AsfFooterProps } from '@ui/types'

export default defineComponent({
  name: 'AsfFooter',
  props: {
    /** Newsletter props */
    newsletter: { type: Object as PropType<AsfFooterProps['newsletter']>, default: null }
  },
  setup(_props: AsfFooterProps, { emit }) {
    const onSubmit = (email: string) => {
      emit('newsletter:submit', email)
    }

    return { onSubmit }
  }
})
</script>
<style lang="postcss">
@import '@components/organisms/Footer/Footer.css';
</style>
