<template>
  <div class="asf-mega-menu">
    <div v-click-outside="closeMenu" class="asf-mega-menu__inner" ref="menuContentRef" v-e2e="'mega-menu-inner'">
      <nav
        class="asf-mega-menu__nav"
        itemtype="https://www.schema.org/SiteNavigationElement"
        itemscope
        role="navigation"
      >
        <AsfList
          :aria-label="$t('megaMenu.navigation')"
          class="asf-mega-menu__list"
          role="menu"
          @keydown="handleKeydownEvent($event)"
        >
          <AsfMegaMenuItem
            v-for="category in categories"
            :key="category.slug"
            :ref="category.slug"
            :category="category"
            class="asf-mega-menu__list-item"
            v-e2e="`ta-category-top-desktop-${category.slug}`"
            @submenu:toggle="handleSubmenuToggle"
          >
            <template v-if="Boolean(category.items?.length)" #submenu="{ attrHidden, handleClose }">
              <div :aria-hidden="attrHidden" class="asf-mega-menu__panel" role="menu" :aria-label="category.label">
                <AsfWrapper class="asf-mega-menu__wrapper" role="none">
                  <AsfMegaMenuColumn
                    v-if="category.items?.length"
                    :items="category.items"
                    :parent-category="category"
                    :level="level"
                    :class="['asf-mega-menu__wrapper-inner', { 'm-wrapped': (category.items?.length ?? 0) > 6 }]"
                  />
                  <AsfButton
                    class="asf-mega-menu__close"
                    :title="`${$t('megaMenu.close')}`"
                    :aria-label="`${$t('megaMenu.close')}`"
                    aria-hidden="true"
                    @click="handleClose"
                    @keydown.enter="handleClose"
                  >
                    <AsfIcon :id="`${category.slug}-close`" name="close" size="5" />
                  </AsfButton>
                </AsfWrapper>
              </div>
            </template>
          </AsfMegaMenuItem>
        </AsfList>
      </nav>
    </div>
    <AsfOverlay :visible="menuVisible" />
  </div>
</template>

<script lang="ts">
import throttle from 'lodash.throttle'
import { AsfKeyValue, type AsfMegaMenuProps, type AsfSetTimeout } from '@ui/types'
import { closeSubmenu, getAccessibleItems, getElementRefByID } from './MegaMenu.utils'

export default defineComponent({
  name: 'AsfMegaMenu',
  props: {
    taxonomy: { type: Array as PropType<AsfMegaMenuProps['taxonomy']>, required: true },
    isSticky: { type: Boolean as PropType<AsfMegaMenuProps['isSticky']>, default: true }
  },
  setup(props: AsfMegaMenuProps, { emit }) {
    let timer: null | AsfSetTimeout
    let stopScrollHandler: null | ReturnType<typeof addEventListenerUtil> = null
    const instance = useCurrentInstance()

    const level = ref(1)
    const isStuck = ref(false)
    const menuContentRef = ref<Element | null>(null)
    const menuVisible = ref(false)
    const elementPosition: Ref<ReturnType<typeof getElementCoordinates>> = ref({ top: 0, left: 0 })

    const { isMediaMatch: isSmallOrMediumView } = useMediaQuery()
    const categories = computed(() => props.taxonomy.at(0)?.items || [])
    const accessibleItems = computed(() => getAccessibleItems(categories.value))

    const {
      focusableItems,
      currentElement,
      setFocusToPreviousItem,
      setFocusToNextItem,
      setFocusToFirstItem,
      setFocusToLastItem
    } = useAccessibility(accessibleItems)

    const closeMenu = () => {
      if (!menuVisible.value || !focusableItems.value) return
      closeSubmenu(focusableItems.value, instance)
    }

    const handleKeyboardSelect = (event: KeyboardEvent) => {
      const domNode = event.target as HTMLLinkElement

      if (domNode && domNode.href) {
        closeMenu()
        emit('menu:keyboard-redirect', { path: domNode.getAttribute('href') })
      }
    }

    const setFocusToItem = (id: string) => {
      let hasOpenedMenu = false

      focusableItems.value?.forEach((item) => {
        const currentEl = getElementRefByID(item, instance)
        currentEl?.setAttributeTabIndex('-1')

        if (currentEl?.hasSubmenuOpen()) {
          hasOpenedMenu = true
          currentEl.closeMenu()
        }
      })

      const currentItem = getElementRefByID(id, instance)

      currentItem?.setAttributeTabIndex('0')
      currentItem?.setFocusToElement()

      if (hasOpenedMenu) {
        currentItem?.openMenu()
      }

      currentElement.value = id
    }

    const handleSubmenuToggle = (value: boolean) => {
      if (value && focusableItems.value) {
        closeSubmenu(focusableItems.value, instance)
      }

      if (timer) {
        clearTimeout(timer)
      }

      timer = setTimeout(() => {
        menuVisible.value = value

        clearTimeout(timer as AsfSetTimeout)
      }, 1)
    }

    const handleKeydownEvent = (event: KeyboardEvent) => {
      let preventActions = false
      const element = getElementRefByID(String(currentElement.value), instance)
      switch (event.key) {
        case AsfKeyValue.PAGEUP:
        case AsfKeyValue.HOME:
          setFocusToFirstItem(setFocusToItem)
          preventActions = true
          break

        case AsfKeyValue.PAGEDOWN:
        case AsfKeyValue.END:
          setFocusToLastItem(setFocusToItem)
          preventActions = true
          break

        case AsfKeyValue.LEFT:
          setFocusToPreviousItem(setFocusToItem)
          preventActions = true
          break

        case AsfKeyValue.RIGHT:
          setFocusToNextItem(setFocusToItem)
          preventActions = true
          break

        case AsfKeyValue.DOWN:
        case AsfKeyValue.SPACE:
          element?.openMenu(true)
          element?.setFocusToFirstItem()
          preventActions = true
          break

        case AsfKeyValue.UP:
          element?.openMenu(true)
          element?.setFocusToLastItem()
          preventActions = true
          break

        case AsfKeyValue.TAB:
          closeMenu()
          setFocusToNextItem(setFocusToItem)
          break

        case AsfKeyValue.ENTER:
          handleKeyboardSelect(event)
          preventActions = true
          break

        default:
          break
      }

      if (preventActions) {
        event.preventDefault()
        event.stopPropagation()
      }
    }

    const scrollHandler = () => {
      if (elementPosition.value) {
        const currentScrollPosition = window.scrollY || document.documentElement.scrollTop
        isStuck.value = currentScrollPosition >= elementPosition.value.top
        emit('menu:sticky', isStuck.value)
      }
    }

    watch(isSmallOrMediumView, () => {
      closeMenu()

      if (process.client && !elementPosition.value?.top) {
        elementPosition.value = getElementCoordinates(menuContentRef.value as Element)
      }
    })

    onMounted(() => {
      EventBus.on('submenu:close', closeMenu)

      if (process.client && props.isSticky) {
        elementPosition.value = getElementCoordinates(menuContentRef.value as Element)

        const handler = throttle(scrollHandler, 50)
        stopScrollHandler = addEventListenerUtil({ type: 'scroll', handler })
      }
    })
    onBeforeUnmount(() => {
      EventBus.off('submenu:close', closeMenu)

      if (stopScrollHandler) {
        stopScrollHandler()
      }
    })

    return {
      level,
      categories,
      menuVisible,
      menuContentRef,
      isStuck,
      closeMenu,
      handleKeydownEvent,
      handleSubmenuToggle
    }
  }
})
</script>
<style lang="postcss">
@import '@components/organisms/MegaMenu/MegaMenu.css';
</style>
