<template>
  <div
    v-click-outside="closeDropdown"
    role="application"
    :class="[
      'asf-dropdown',
      {
        'is-active': isDropdownOpen,
        'is-required': required,
        'is-disabled': disabled,
        'is-invalid': !valid
      }
    ]"
    @click="toggleDropdown"
    @keydown="handleKeydown"
  >
    <!-- @slot Custom label of dropdown -->
    <slot v-if="label && showLabel" name="content-label">
      <div id="dropdownLabel" class="asf-dropdown__label">
        {{ label }}
      </div>
    </slot>
    <div class="asf-dropdown__inner">
      <div
        ref="toggler"
        class="asf-dropdown__wrapper"
        tabindex="0"
        role="combobox"
        aria-haspopup="listbox"
        :aria-expanded="isDropdownOpen ? 'true' : 'false'"
        :aria-label="label.toString()"
      >
        <slot name="content-selected" :item="selected">
          {{ placeholder }}
        </slot>
        <!-- @slot Custom arrow icon of dropdown -->
        <slot name="content-icon">
          <AsfChevron class="asf-dropdown__chevron" :appearance="chevronAppearance" />
        </slot>
      </div>
      <Transition name="asf-expand">
        <ul
          v-if="isDropdownOpen"
          :aria-expanded="isDropdownOpen"
          role="listbox"
          aria-labelledby="dropdownLabel"
          class="asf-dropdown__content"
        >
          <AsfDropdownOption
            v-for="(item, index) in items"
            :ref="`${item.value}`"
            :key="`${item.value || index}`"
            :selected-value="selectedOptionValue"
            :value="item.value"
            :aria-hidden="selectedOptionValue === item.value && !showActive ? true : null"
            @click.stop="handleOptionClick(item)"
            @keydown="handleOptionKeydown($event, item)"
          >
            <slot :item="item" />
          </AsfDropdownOption>
        </ul>
      </Transition>
      <div v-if="!valid" class="asf-dropdown__error-message">
        <transition name="asf-fade">
          <!-- @slot Custom error message of dropdown -->
          <slot name="content-error-message" v-bind="{ errorMessage }">
            <span> {{ errorMessage }} </span>
          </slot>
        </transition>
      </div>
      <div v-if="caption" class="asf-dropdown__caption">
        <!-- @slot Custom caption message of dropdown -->
        <slot name="content-caption-message" v-bind="{ caption }">
          <div>{{ caption }}</div>
        </slot>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { TranslateResult } from 'vue-i18n'
import type { AsfChevronProps, AsfDropdownOption, AsfDropdownProps } from '@ui/types'
import { AsfKeyValue } from '@ui/types'

type DropdownFocusableElement = Element & {
  focus: () => void
}

const props = withDefaults(defineProps<AsfDropdownProps>(), {
  showLabel: true,
  valid: true
})
const emit = defineEmits(['update:selected', 'keyDownChange'])
const instance = useCurrentInstance()
const { items } = toRefs<AsfDropdownProps>(props)
const accessibleItems = computed(() => {
  let values = items.value.map((item) => item.value)

  if (!props.showActive && props.selected) {
    values = values.filter((item) => item !== props.selected?.value)
  }

  return values
})

const {
  currentElement,
  firstElement,
  lastElement,
  setFocusToPreviousItem,
  setFocusToNextItem,
  setFocusToFirstItem,
  setFocusToLastItem
} = useAccessibility(accessibleItems)

const isDropdownOpen = ref(false)
const isFocused = ref<boolean | undefined>(false)
const selectedOptionValue = ref(props.selected?.value || '')
const chevronAppearance = computed<AsfChevronProps['appearance']>(() => (isDropdownOpen.value ? 'top' : 'bottom'))

const setFocusToItem = (item: TranslateResult) => {
  currentElement.value = item

  nextTick(() => {
    const refEl = instance.$refs[`${item}`]

    if (refEl) {
      return (refEl as any)[0]?.$el?.focus()
    }
  })
}

const openDropDown = (focusFirstItem?: boolean, focusLastItem?: boolean) => {
  isDropdownOpen.value = true
  isFocused.value = focusFirstItem || focusLastItem

  if (focusFirstItem && firstElement.value) {
    setFocusToItem(firstElement.value)
  }

  if (focusLastItem && lastElement.value) {
    setFocusToItem(lastElement.value)
  }
}

const closeDropdown = (returnFocus?: boolean) => {
  isDropdownOpen.value = false
  isFocused.value = false

  if (returnFocus) {
    instance?.$nextTick(() => (instance.$refs.toggler as DropdownFocusableElement).focus())
  }
}

const toggleDropdown = () => {
  const action = isDropdownOpen.value ? closeDropdown : openDropDown

  action()
}

const handleOptionClick = (item: AsfDropdownOption) => {
  selectedOptionValue.value = item.value
  emit('update:selected', item)
  closeDropdown()
}

const handleKeydown = (event: KeyboardEvent) => {
  let preventActions = false
  switch (event.key) {
    case AsfKeyValue.PAGEUP:
    case AsfKeyValue.HOME:
      if (isFocused.value) {
        setFocusToFirstItem(setFocusToItem)
      }

      preventActions = true
      break

    case AsfKeyValue.PAGEDOWN:
    case AsfKeyValue.END:
      if (isFocused.value) {
        setFocusToLastItem(setFocusToItem)
      }

      preventActions = true
      break

    case AsfKeyValue.UP:
      if (isFocused.value) {
        setFocusToPreviousItem(setFocusToItem)
      } else {
        openDropDown(false, true)
        setFocusToLastItem(setFocusToItem)
      }
      preventActions = true
      break

    case AsfKeyValue.DOWN:
      if (isFocused.value) {
        setFocusToNextItem(setFocusToItem)
      } else {
        openDropDown(true, false)
        setFocusToFirstItem(setFocusToItem)
      }
      preventActions = true
      break

    case AsfKeyValue.ENTER:
    case AsfKeyValue.SPACE:
      openDropDown(true, false)
      setFocusToFirstItem(setFocusToItem)
      preventActions = true
      break

    case AsfKeyValue.TAB:
    case AsfKeyValue.ESC:
      closeDropdown(true)
      break
  }

  if (preventActions) {
    event.preventDefault()
    event.stopPropagation()
  }
}

const handleOptionKeydown = (event: KeyboardEvent, item: AsfDropdownOption) => {
  let preventActions = false
  switch (event.key) {
    case AsfKeyValue.ENTER:
    case AsfKeyValue.SPACE:
      handleOptionClick(item)
      emit('keyDownChange', item)
      preventActions = true
      break
  }

  if (preventActions) {
    event.preventDefault()
    event.stopPropagation()
  }
}
</script>
<style lang="postcss">
@import '@components/molecules/Dropdown/Dropdown.css';
</style>
