<template>
  <AsfListItem
    role="none"
    v-bind="$attrs"
    @keydown="handleKeydownEvent"
    @mouseenter="shouldUseMouseActions && !shouldPreventRouteAction ? handleMouseEvent($event) : null"
    @mouseleave="shouldUseMouseActions && !shouldPreventRouteAction ? handleMouseEvent($event) : null"
  >
    <AsfMenuItem
      ref="menuItem"
      v-e2e="`ta-category-desktop-${category.slug}`"
      :link="!shouldPreventRouteAction ? getCategoryURL(category) : undefined"
      :label="category.label"
      :show-icon="false"
      :aria-label="category.label"
      :aria-haspopup="String(Boolean(category.items?.length))"
      :aria-expanded="attrExpanded"
      :tabindex="attrTabIndex || '0'"
      :class="{ 'm-style-custom': category.isHighlighted }"
      class="asf-mega-menu__item"
      role="menuitem"
      @click="handleNativeClick"
    />
    <slot name="submenu" v-bind="{ attrHidden, handleClose }" />
  </AsfListItem>
</template>
<script lang="ts">
import { AsfKeyValue, type AsfMegaMenuItemProps } from '@ui/types'
import { getAllAccessibleItems, OPEN_MENU_DELAY } from '../MegaMenu.utils'
import type { AsfMenuItem } from '#components'

export default defineComponent({
  name: 'AsfMegaMenuItem',
  props: {
    category: { type: Object as PropType<AsfMegaMenuItemProps['category']>, required: true }
  },
  setup(props: AsfMegaMenuItemProps, { emit, attrs }) {
    const { getCategoryURL } = useURL()
    const { isTouchDevice } = useTouchDevice()

    let timerLeave: (() => void) | null = null
    let timerEnter: (() => void) | null = null

    const menuItem: Ref<InstanceType<typeof AsfMenuItem> | null> = ref(null)
    const isPrevented = ref(true)
    const isSubmenuOpen = ref(false)
    const isSubmenuFocus = ref(false)
    const attrTabIndex = ref(attrs.tabindex)
    const attrExpanded = ref(props.category.items?.length ? 'false' : undefined)
    const attrHidden = ref(attrExpanded.value && 'true')
    const shouldUseMouseActions = computed(() => props.category.items?.length)
    const shouldPreventRouteAction = computed(
      () => isPrevented.value && props.category.items?.length && isTouchDevice.value
    )
    const accessibleItems = computed(() => props.category.items && getAllAccessibleItems(props.category.items))
    const { currentElement, setFocusToNextItem, setFocusToPreviousItem, setFocusToFirstItem, setFocusToLastItem } =
      useAccessibility(accessibleItems)

    const setAttributeTabIndex = (value: string) => (attrTabIndex.value = value)
    const hasSubmenuOpen = () => isSubmenuOpen.value

    const setFocusToItem = (menuItemId: string) => {
      currentElement.value = menuItemId
      EventBus.emit('menuitem:focus', menuItemId)
    }
    const setFocusToElement = () => {
      const element = menuItem.value?.$el as HTMLLinkElement
      nextTick(() => element?.focus({ preventScroll: true }))
    }

    const openMenu = (isFocus?: boolean) => {
      emit('submenu:toggle', true)
      attrExpanded.value = 'true'
      attrHidden.value = 'false'
      isSubmenuOpen.value = true
      isSubmenuFocus.value = Boolean(isFocus)
      isPrevented.value = false
    }

    const closeMenu = () => {
      if (!isSubmenuOpen.value) {
        return null
      }

      attrExpanded.value = 'false'
      attrHidden.value = 'true'
      isSubmenuOpen.value = false
      isSubmenuFocus.value = false
      isPrevented.value = true
      return emit('submenu:toggle', false)
    }

    const preventActions = (event: Event) => {
      event.preventDefault()
      event.stopPropagation()
    }

    const handleClose = (event: MouseEvent) => {
      preventActions(event)
      closeMenu()
    }
    const handleNativeClick = (event: MouseEvent) => {
      if (!shouldPreventRouteAction.value) {
        EventBus.emit('submenu:close')
        return null
      }

      preventActions(event)
      return openMenu()
    }
    const handleMouseEvent = (event: MouseEvent) => {
      switch (event.type) {
        case 'mouseenter':
          timerLeave && timerLeave()
          timerEnter = timeout(() => openMenu(), OPEN_MENU_DELAY)
          break

        case 'mouseleave':
          timerEnter && timerEnter()
          timerLeave = timeout(() => closeMenu(), OPEN_MENU_DELAY)
          break

        default:
          break
      }
    }
    const handleKeydownEvent = (event: KeyboardEvent) => {
      if (!isSubmenuFocus.value) {
        return ''
      }

      switch (event.key) {
        case AsfKeyValue.PAGEUP:
        case AsfKeyValue.HOME:
          setFocusToFirstItem(setFocusToItem)
          preventActions(event)
          break

        case AsfKeyValue.PAGEDOWN:
        case AsfKeyValue.END:
          setFocusToLastItem(setFocusToItem)
          preventActions(event)
          break

        case AsfKeyValue.DOWN:
          setFocusToNextItem(setFocusToItem)
          preventActions(event)
          break

        case AsfKeyValue.UP:
          setFocusToPreviousItem(setFocusToItem)
          preventActions(event)
          break

        case AsfKeyValue.ESC:
          closeMenu()
          setFocusToElement()
          preventActions(event)
          break

        default:
          break
      }

      return null
    }

    return {
      menuItem,
      attrExpanded,
      attrHidden,
      attrTabIndex,
      shouldUseMouseActions,
      shouldPreventRouteAction,
      closeMenu,
      openMenu,
      handleClose,
      handleMouseEvent,
      handleNativeClick,
      handleKeydownEvent,
      hasSubmenuOpen,
      setFocusToElement,
      setFocusToFirstItem: () => setFocusToFirstItem(setFocusToItem),
      setFocusToLastItem: () => setFocusToLastItem(setFocusToItem),
      setAttributeTabIndex,
      getCategoryURL
    }
  }
})
</script>
