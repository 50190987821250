<template>
  <div class="asf-footer-newsletter">
    <AsfHeading tag="h2" appearance="h1">
      <span class="asf-footer-newsletter__title">
        <slot>{{ content ? content.title : '' }}</slot>
      </span>
      <template #content-subtitle>
        <span class="asf-footer-newsletter__subtitle">
          <slot name="content-subtitle">{{ content ? content.subtitle : '' }}</slot>
        </span>
      </template>
    </AsfHeading>

    <form class="asf-footer-newsletter__form" @submit.prevent="handleSubmit(inputValue)">
      <div class="asf-footer-newsletter__input-wrapper">
        <AsfInput
          v-model="inputValue"
          :label="$t('footer.newsletterPlaceholder')"
          :show-label="false"
          :placeholder="$t('footer.newsletterPlaceholder')"
          type="email"
          class="asf-footer-newsletter__input"
        />
        <AsfButton class="asf-button-accent">
          {{ $t('footer.signUpButton') }}
        </AsfButton>
      </div>
      <div v-show="inputValue" class="asf-footer-newsletter__checkbox-wrapper">
        <AsfCheckbox
          :value="checked"
          :required="true"
          :name="$t('footer.checkboxName')"
          :label="$t('footer.checkboxLabel')"
          :custom-error-message="$t('footer.checkboxCustomErrorMessage')"
          @input="handleChange"
        />
        <AsfLink prevent class="asf-footer-newsletter__link">
          {{ $t('footer.privacyText') }}
        </AsfLink>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import type { AsfFooterProps } from '@ui/types'

export default defineComponent({
  name: 'AsfFooterNewsletter',
  props: {
    /** Newsletter props */
    content: { type: Object as PropType<AsfFooterProps['newsletter']>, default: () => ({}) }
  },
  setup(_props, context) {
    const inputValue = ref('')
    const checked = ref(false)
    const handleChange = () => {
      checked.value = !checked.value
    }
    const handleClearInput = () => (inputValue.value = '')

    const handleSubmit = (email: string) => {
      if (!checked.value) return

      context.emit('onSubmit', email)
      handleClearInput()
    }

    return { inputValue, checked, handleChange, handleSubmit }
  }
})
</script>
