<script lang="ts" setup>
import type { AsfHeaderProps, AsfIconProps, AsfLocaleObject } from '@ui/types'

interface AppHeaderProps<T = any> {
  contentTop?: Array<T>
  contentBottom?: Array<T>
}
defineProps<AppHeaderProps>()

const router = useRouter()
const { taxonomy } = useTaxonomy()
const { getURL } = useURL()
const { load: loadSession, session } = useSession()
const { isAuthenticated } = useCustomer()
const { locales: tmpLocales, localeProperties } = useI18n()
const rawLocales = tmpLocales.value
const currentLocale = localeProperties.value
const { toggleSearch, toggleHamburgerMenu, isHamburgerMenuOpen } = useUiState()
const { urls: alternativeUrls } = useAlternativePageUrls()

if (process.client) {
  loadSession()
}

const isStuck = ref(false)

const { isMediaMatch: isSmallOrMediumView } = useMediaQuery()
const totalItems = computed(() => {
  const sessionValue = session.value
  const counts = {
    cart: 0,
    wishlist: 0
  }

  if (sessionValue) {
    counts.cart = Number(sessionValue.cart?.lineItems.reduce((count, li) => count + li.quantity, 0) || 0)
    counts.wishlist = Number(sessionValue.wishlist?.total || 0)
  }

  return counts
})
const headerLinks = computed<AsfHeaderProps['headerLinks']>(() => ({
  // @ts-expect-error
  account: isAuthenticated.value ? getURL({ name: 'account' }) : getURL({ name: 'login' }),
  cart: getURL({ name: 'cart' }),
  logo: getURL({ name: 'index' }),
  wishlist: getURL({ name: isAuthenticated.value ? 'account-wishlist' : 'wishlist' })
}))
const locales = computed(() => ({
  ...currentLocale,
  id: currentLocale.iso || currentLocale.code,
  iconName: `flag-${currentLocale.iso?.split('-')[1]?.toLowerCase()}` as AsfIconProps['name'],
  label: currentLocale.iso?.split('-').reverse().join(' / ').toUpperCase() || currentLocale.code,
  value: currentLocale.code,
  items: rawLocales?.map((item: any) => ({
    ...item,
    id: item.iso,
    iconName: `flag-${item.iso.split('-')[1]?.toLowerCase()}` as AsfIconProps['name'],
    label: item.iso.split('-').reverse().join(' / ').toUpperCase(),
    value: item.code,
    link: alternativeUrls.value[item.code] || getURL({ name: 'index' }, item.code),
    isHidden: item.code === currentLocale.code
  }))
}))

const keyboardEventRedirect = ({ path }: { path: string }) => router.push({ path })
const handleStickyState = (isSticky: boolean) => (isStuck.value = isSticky)

const changeLocale = (item: AsfLocaleObject) => {
  // we do need hard reload in order to re-apply different baskets for different locales
  if (item.link) {
    window.location.assign(item.link)
  }
}

const navigateToWishlist = () =>
  router.push(getURL(session.value.isRegistered ? { name: 'account-wishlist' } : { name: 'wishlist' }))
</script>
<template>
  <div
    id="pageHeader"
    v-e2e="isStuck ? 'sticky-header' : 'global-header'"
    class="app-header"
    :class="{ 'is-sticky-desktop': isStuck }"
  >
    <AsfPromoBar v-if="contentTop" appearance="top">
      <ContentSlot :content="contentTop" />
    </AsfPromoBar>

    <AsfHeader
      :is-logged-in="isAuthenticated"
      :header-links="headerLinks"
      :wishlist-total="totalItems.wishlist"
      :cart-total="totalItems.cart"
      @open:search="toggleSearch"
      @open:mobileMenu="toggleHamburgerMenu"
      @open:wishlist="navigateToWishlist"
    >
      <template #content-locale>
        <HeaderLocaleSelector :locales="locales" @change-locale="changeLocale" />
      </template>
      <template #content-wishlist-icon="{ wishlistTotal, openWishlist }">
        <WishlistIcon
          class="asf-header__actions__item m-wishlist"
          :selected="Boolean(wishlistTotal)"
          :static-aria-text="`${$t('wishlist.phrase')}`"
          :loading="false"
          @click="openWishlist"
          @keydown.prevent.enter="openWishlist"
          @keydown.prevent.space="openWishlist"
        />
      </template>
    </AsfHeader>

    <AsfHamburgerMenu
      v-if="isSmallOrMediumView && taxonomy[0]?.items"
      :taxonomy="taxonomy[0].items"
      :visible="isHamburgerMenuOpen"
      :locales="locales"
      @close:hamburger-menu="toggleHamburgerMenu"
    >
      <template #content-user-nav="{ tabindex, handleNativeClick }">
        <HeaderUserNavigation
          :is-authenticated="isAuthenticated"
          :tabindex="tabindex"
          @click:item="handleNativeClick"
        />
      </template>
    </AsfHamburgerMenu>

    <AsfMegaMenu
      :taxonomy="taxonomy"
      @menu:keyboard-redirect="keyboardEventRedirect"
      @menu:sticky="handleStickyState"
    />

    <AsfPromoBar v-if="contentBottom" appearance="bottom">
      <ContentSlot :content="contentBottom" />
    </AsfPromoBar>
  </div>
</template>
