<template>
  <div v-if="visible" class="asf-search-panel" ref="content">
    <div
      v-click-outside="forceClose"
      v-focus-trap="useFocusTrap"
      class="asf-search-panel__wrapper"
      @keydown="handleKeydown"
    >
      <div class="asf-search-panel__top">
        <AsfWrapper>
          <div class="asf-search-panel__form">
            <AsfInputSearch
              ref="formComponent"
              :name="aria.name"
              :input-a11y="aria.input"
              @search:change="handleSearchChange"
              @search:submit="handleSearchSubmit"
            />
            <AsfButton class="asf-search-panel__form-close" @click="forceClose">
              {{ $t('action.close') }}
            </AsfButton>
          </div>
        </AsfWrapper>
      </div>

      <AsfLoader class="asf-search-panel__bottom" :loading="loading" updating>
        <div class="asf-suggestion" role="listbox" v-bind="aria.list" ref="suggestionsRef">
          <AsfWrapper role="none">
            <div v-if="result.guess" role="none" class="asf-suggestion__section">
              <AsfLink
                v-sanitize="$t('search.suggested.guess', { guess: `<span>${result.guess}</span>` })"
                :link="result.guessLink"
                class="asf-suggestion__guess"
                @click="forceClose"
                @keydown.enter="forceClose"
              />
            </div>
            <template v-if="result.suggestions">
              <div
                v-for="suggestion in result.suggestions"
                :key="suggestion.type"
                role="none"
                class="asf-suggestion__section"
              >
                <div role="none" class="asf-suggestion__title">
                  {{ suggestion.title || $t(`search.suggested.${suggestion.type}`) }}
                </div>

                <AsfLink
                  v-for="(element, index) in suggestion.data"
                  :key="element.slug"
                  v-e2e="'search-suggestions-wrapper'"
                  :link="element.slug"
                  role="option"
                  class="asf-suggestion__link"
                  :class="[`asf-suggestion__link--${suggestion.type}`]"
                  :aria-label="$t(`search.suggested.${suggestion.type}Complete`, { name: element.label })"
                  @click="productClicked(suggestion.type, element.product, index)"
                  @keydown.enter="productClicked(suggestion.type, element.product, index)"
                >
                  <div v-if="suggestion.type === 'product'" class="asf-suggestion__product">
                    <div v-if="element.image && element.image.src" class="asf-suggestion__picture-wrapper">
                      <AsfImage
                        adaptive
                        :alt="element.image.alt || element.label"
                        v-bind="element.image"
                        class="asf-suggestion__picture"
                      />
                    </div>
                    <div class="asf-suggestion__heading">
                      <AsfHeading tag="h2" appearance="h5">
                        {{ element.label }}
                      </AsfHeading>
                      <AsfPrice v-if="element.prices" :prices="element.prices" />
                    </div>
                  </div>
                  <template v-else>
                    <span class="asf-suggestion__link--label">{{ element.label }}</span>
                    <span v-if="element.subLabel">{{ element.subLabel }}</span>
                  </template>
                </AsfLink>
              </div>
            </template>
            <template v-if="isResponse && result.suggestions">
              <div role="none" class="asf-suggestion__section">
                <AsfButton
                  class="asf-button-secondary asf-suggestion__view-all js-submit"
                  @click="handleSearchSubmit(searchPhrase)"
                >
                  {{ $t('search.suggested.viewAll') }}
                </AsfButton>
              </div>
              <p class="asf-suggestion__alert" role="alert">
                {{ $t('search.suggested.searchCount', { suggestionCount }) }}
              </p>
            </template>
          </AsfWrapper>
        </div>
      </AsfLoader>
    </div>

    <AsfOverlay visible />
  </div>
</template>
<script lang="ts">
import debounce from 'lodash.debounce'
import { type FocusableElement, getFocusableChildren } from 'shared/directives'
import { AsfKeyValue, type AsfSearchPanelProps } from '@ui/types'
import type { AgnosticProduct } from 'shared/types'
import type { AsfInputSearch } from '#components'

type DisposableTimeout = (() => void) | null

const PHRASE_MIN_LENGTH = 3
const SEARCH_TIMOUT = 300

export default defineComponent({
  name: 'AsfSearchPanel',
  emits: ['search:close', 'search:submit', 'search:change', 'search:redirect', 'product:clicked'],
  props: {
    visible: { type: Boolean as PropType<AsfSearchPanelProps['visible']>, default: true },
    isResponse: { type: Boolean as PropType<AsfSearchPanelProps['isResponse']>, default: false },
    loading: { type: Boolean as PropType<AsfSearchPanelProps['loading']>, default: false },
    result: { type: Object as PropType<AsfSearchPanelProps['result']>, default: () => ({}) },
    /** Defines to use init Focus Trap directive */
    useFocusTrap: { type: Boolean as PropType<AsfSearchPanelProps['useFocusTrap']>, default: true }
  },

  setup(props: AsfSearchPanelProps, context) {
    const instance = useCurrentInstance()
    const { t } = useI18n()
    const suggestionsRef = ref<HTMLElement | null>(null)
    const accessibleItems = ref<FocusableElement[]>([])
    const { focusableItems, currentElement, setFocusToPreviousItem, setFocusToNextItem } = useAccessibility(
      computed(() => accessibleItems.value)
    )

    const searchPhrase = ref('')

    const forceClose = () => {
      EventBus.emit('disable:highlighter')
      context.emit('search:close')
    }

    const aria = computed(() => {
      const name = 'search-panel'
      const listId = `${name}-list`
      const inputId = `${name}-input`

      return {
        name,
        input: {
          id: inputId,
          role: 'combobox',
          'aria-autocomplete': 'list',
          'aria-controls': listId,
          'aria-owns': listId,
          'aria-expanded': 'true',
          'aria-haspopup': 'true',
          'aria-label': `${t('search.combobox')}`
        },
        list: {
          'aria-label': `${t('search.word')}`,
          'aria-busy': true,
          id: listId
        }
      }
    })

    const suggestionCount = computed(
      () => props.result.suggestions && props.result.suggestions.reduce((acc, item) => acc + item.data.length, 0)
    )

    const formComponent = ref<InstanceType<typeof AsfInputSearch> | null>(null)
    watch(
      formComponent,
      (component) => {
        if (!component) return
        const formElement = component.$el as HTMLFormElement
        const inputElement = formElement.querySelector<HTMLInputElement>('.asf-input__field')

        if (inputElement) inputElement.focus()
      },
      { immediate: true }
    )

    const handleSearchSubmit = (phrase: string) => {
      const pseudoFocusElement = currentElement.value
      if (pseudoFocusElement && !pseudoFocusElement.classList.contains('js-submit')) {
        return pseudoFocusElement.click()
      }

      context.emit('search:submit', phrase)
    }

    const emitSearchEvent = debounce((phrase: string) => {
      context.emit('search:change', phrase.length >= PHRASE_MIN_LENGTH ? phrase : '')
    }, SEARCH_TIMOUT)

    const handleSearchChange = (phrase: string) => {
      if (currentElement.value) {
        currentElement.value.classList.remove(FOCUS_CLASSNAME)
        currentElement.value = undefined
      }

      searchPhrase.value = phrase

      return emitSearchEvent(phrase)
    }

    let disposableTimeout: DisposableTimeout = null
    const setFocusableChild = () => {
      nextTick(() => {
        if (!suggestionsRef.value) {
          return
        }

        accessibleItems.value = getFocusableChildren(suggestionsRef.value as HTMLElement)
        disposableTimeout = timeout(() => (currentElement.value = undefined), 0)
      })
    }

    onMounted(setFocusableChild)
    onUpdated(setFocusableChild)

    const setFocusToElement = (el: FocusableElement) => {
      if (!el) return

      nextTick(() => {
        currentElement.value = el
        currentElement.value.classList.add(FOCUS_CLASSNAME)
        currentElement.value.focus()
        focusableItems.value?.forEach((element) => {
          if (element !== el) {
            element.classList.remove(FOCUS_CLASSNAME)
          }
        })
      })
    }

    const getSuggestionsData = () => {
      return props.result.suggestions
        ?.filter((suggestion) => suggestion.type === 'product')
        .flatMap((suggestion) => suggestion.data)
    }

    const removeAllFocusClasses = () => {
      nextTick(() => {
        focusableItems.value?.forEach((element) => element.classList.remove(FOCUS_CLASSNAME))
      })
    }

    const handleKeydown = (event: KeyboardEvent) => {
      let preventActions = false

      switch (event.key) {
        case AsfKeyValue.DOWN:
          preventActions = true
          setFocusToNextItem(setFocusToElement)
          break
        case AsfKeyValue.UP:
          preventActions = true
          setFocusToPreviousItem(setFocusToElement)
          break
        case AsfKeyValue.TAB:
          removeAllFocusClasses()
          break

        default:
          break
      }

      if (preventActions) {
        event.preventDefault()
        event.stopPropagation()
      }
    }

    useBodyScrollLock(props, instance, forceClose)

    onBeforeUnmount(() => {
      if (emitSearchEvent.cancel) emitSearchEvent.cancel()
      if (disposableTimeout) disposableTimeout()
    })

    const productClicked = (suggestionType: string, product: AgnosticProduct, position: number) => {
      forceClose()
      if (suggestionType === 'product') {
        const suggestedProducts = getSuggestionsData()

        if (suggestedProducts) {
          context.emit('product:clicked', { product, position: position + 1 })
          const suggested = suggestedProducts[position]
          if (suggested) {
            context.emit('search:redirect', suggested.slug)
          }
        }
      }
    }

    return {
      forceClose,
      handleKeydown,
      handleSearchSubmit,
      handleSearchChange,
      searchPhrase,
      suggestionCount,
      formComponent,
      aria,
      suggestionsRef,
      productClicked
    }
  }
})
</script>
<style lang="postcss">
@import '@components/organisms/SearchPanel/SearchPanel.css';
</style>
