<template>
  <AsfSidebar class="asf-hamburger-menu" :visible="visible" @sidebar:close="close">
    <template #content-bar="{ title, closeEventHandler }">
      <template v-if="title">
        <AsfLink v-if="parentCategory" :link="getCategoryURL(parentCategory)" @click="closeEventHandler">
          <AsfHeading tag="h3" appearance="h5">
            {{ title }}
          </AsfHeading>
        </AsfLink>
        <AsfHeading v-else tag="h3" appearance="h5">
          {{ title }}
        </AsfHeading>
      </template>
    </template>
    <template v-slot="{ handleLevelChange, closeEventHandler }">
      <AsfSidebarSlidingPanel
        #default="{ currentState }"
        tag="nav"
        :aria-label="$t('megaMenu.title')"
        @close:panel="setCategoryBreadcrumbs()"
        @change:panel="handleLevelChange"
      >
        <AsfSidebarSlidingContent>
          <AsfSidebarSlidingItem
            v-for="category in taxonomy"
            :id="category.id"
            :key="category.id"
            v-e2e="`ta-category-hamburger-${category.slug}`"
            :label="category.label"
            :link="category.items?.length ? undefined : getCategoryURL(category)"
            :panel-content="category"
            :tabindex="currentState.level ? '-1' : '0'"
            @keydown:right="setCategoryBreadcrumbs(category)"
            @click="handleClick(closeEventHandler, category)"
          />
          <slot
            name="content-user-nav"
            :tabindex="currentState.level && '-1'"
            :handle-native-click="closeEventHandler"
          />
          <AsfSidebarSlidingItem
            v-if="locales"
            :id="locales.id"
            v-e2e="'hamburger-menu-locale-selector'"
            :label="locales.label"
            :aria-label="$t('megaMenu.locales')"
            :panel-content="locales"
            :tabindex="currentState.level && '-1'"
            class="asf-hamburger-menu__locale-item"
          >
            <template #content-icon>
              <AsfIcon v-if="locales.iconName" :name="locales.iconName" :size="['8', '4']" />
            </template>
          </AsfSidebarSlidingItem>
        </AsfSidebarSlidingContent>

        <AsfSidebarSlidingContent v-for="({ id, panelContent }, index) in currentState.panels" :key="id" :level="index">
          <template v-if="panelContent && categoryBreadcrumbs.length">
            <AsfSidebarSlidingItem
              v-for="item in [...panelContent.items, createViewAllLink(panelContent, $t('global.viewAll').toString())]"
              :id="item.id"
              :key="item.id"
              v-e2e="`ta-category-hamburger-${item.slug}`"
              :label="item.label"
              :link="item.items?.length ? undefined : getCategoryURL(item)"
              :panel-content="item"
              :tabindex="currentState.level > index + 1 && '-1'"
              @keydown:right="setCategoryBreadcrumbs(item)"
              @click="handleClick(closeEventHandler, item)"
            />
          </template>
          <template v-else-if="panelContent">
            <AsfSidebarSlidingItem
              v-for="item in panelContent.items"
              v-show="!item.isHidden"
              :id="item.id"
              :key="item.id"
              :label="item.label"
              :link="item.link"
              @click="handleNativeClick(closeEventHandler, item)"
            >
              <template #content-icon>
                <AsfIcon v-if="item.iconName" :name="item.iconName" :size="['8', '4']" class="mr-3" />
              </template>
            </AsfSidebarSlidingItem>
          </template>
        </AsfSidebarSlidingContent>
      </AsfSidebarSlidingPanel>
    </template>
  </AsfSidebar>
</template>
<script lang="ts">
import type { AsfHamburgerMenuProps } from '@ui/types'
import type { AgnosticTaxonomy } from 'shared/types'

export default defineComponent({
  name: 'AsfHamburgerMenu',
  props: {
    taxonomy: { type: Array as PropType<AsfHamburgerMenuProps['taxonomy']>, required: true },
    locales: { type: Object as PropType<AsfHamburgerMenuProps['locales']>, default: null },
    visible: { type: Boolean as PropType<AsfHamburgerMenuProps['visible']>, default: false }
  },
  setup(_props: AsfHamburgerMenuProps, { emit }) {
    const { getCategoryURL } = useURL()
    const categoryBreadcrumbs = ref<AgnosticTaxonomy[]>([])
    const parentCategory = computed(() =>
      categoryBreadcrumbs.value.length ? categoryBreadcrumbs.value[categoryBreadcrumbs.value.length - 1] : null
    )

    const setCategoryBreadcrumbs = (item?: AgnosticTaxonomy) => {
      if (item) {
        categoryBreadcrumbs.value.push(item)

        return
      }

      categoryBreadcrumbs.value.pop()
    }
    const close = () => {
      categoryBreadcrumbs.value = []
      emit('close:hamburger-menu')
    }

    const handleNativeClick = (callback: () => void, item: AgnosticTaxonomy) => {
      if (!item.items?.length) {
        return callback()
      }
    }
    const handleClick = (callback: () => void, item: AgnosticTaxonomy) => {
      setCategoryBreadcrumbs(item)
      handleNativeClick(callback, item)
    }

    return {
      categoryBreadcrumbs,
      parentCategory,
      close,
      setCategoryBreadcrumbs,
      handleNativeClick,
      handleClick,
      getCategoryURL,
      createViewAllLink
    }
  }
})
</script>
<style lang="postcss">
@import '@components/organisms/HamburgerMenu/HamburgerMenu.css';
</style>
