<template>
  <div v-if="enabled" class="asf-back-to-top" :class="{ 'is-hidden': !isVisible }" @click="scrollToTop">
    <AsfButton
      class="asf-back-to-top__button"
      :aria-label="`${$t('backToTop.mobile')}`"
      :title="`${$t('backToTop.mobile')}`"
    >
      <AsfIcon name="back-to-top" class="asf-back-to-top__icon" />
      <span class="asf-back-to-top__message">{{ isSmallView ? $t('backToTop.mobile') : $t('backToTop.desktop') }}</span>
    </AsfButton>
  </div>
</template>
<script lang="ts" setup>
import throttle from 'lodash.throttle'
import { type AsfBackToTopProps, MediaMaxBreakpoints } from '@ui/types'

const props = withDefaults(defineProps<AsfBackToTopProps>(), {
  enabled: true
})
let topPosition
const screenCount = 1.5
const { isMediaMatch: isSmallView } = useMediaQuery(MediaMaxBreakpoints.SM)
const isVisible = ref(false)

let stopScrollHandler: ReturnType<typeof addEventListenerUtil> | null = null

const scrollHandler = () => {
  if (process.client) {
    topPosition = getScrollPosition('top')

    const shouldBeVisible = props.enabled && document.body.scrollHeight >= window.innerHeight * 2
    isVisible.value = Boolean(shouldBeVisible && topPosition > window.innerHeight * screenCount)
  }
}

const scrollToTop = (event: MouseEvent) => {
  window.scrollTo(0, 0)
  // TODO: i.khartov check what type should be for `event.target`
  ;(event.target as any)?.closest('button').blur()
}

onMounted(() => {
  if (process.client) {
    const handler = throttle(scrollHandler, 50)
    stopScrollHandler = addEventListenerUtil({ type: 'scroll', handler })
  }
})

onBeforeUnmount(() => {
  if (stopScrollHandler) {
    stopScrollHandler()
  }
})
</script>
<style lang="postcss">
@import '@components/molecules/BackToTop/BackToTop.css';
</style>
