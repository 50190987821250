<template>
  <div
    v-e2e="`promo-bar-${modifier}`"
    :aria-label="`${$t('accessibility.group.common.promoLine')}`"
    role="note"
    class="asf-promo-bar"
    :class="modifier"
  >
    <AsfWrapper :type="wrapperType" class="asf-promo-bar__inner">
      <slot />
    </AsfWrapper>
  </div>
</template>
<script lang="ts" setup>
import type { AsfPromoBarProps, AsfWrapperType } from '@ui/types'
const props = withDefaults(defineProps<AsfPromoBarProps>(), {
  appearance: 'top'
})
const wrapperType = computed<AsfWrapperType>(() => {
  return props.appearance === 'top' ? 'header' : 'section'
})
const modifier = computed(() => `m-${props.appearance}`)
</script>
<style lang="postcss">
@import '@components/molecules/PromoBar/PromoBar.css';
</style>
