import type { AsfObservableObj, AsfObserver } from '@ui/types'

interface TouchObservableObj extends AsfObservableObj {
  isTouch: Ref<boolean>
}

const observer: AsfObserver<TouchObservableObj> = {}

// eslint-disable-next-line sonarjs/cognitive-complexity
const touchObserver = () => {
  const isTouch = ref<boolean>(false)
  if (process.server) {
    return {
      isTouch: () => isTouch.value,
      dispose() {}
    }
  }

  const onTouchDevice = (e: MediaQueryListEvent) => {
    const observerRecord = observer.touchDevice
    if (window.PointerEvent && 'maxTouchPoints' in navigator) {
      // if Pointer Events are supported, just check maxTouchPoints
      if (navigator.maxTouchPoints > 0 && observerRecord) {
        observerRecord.isTouch.value = true
        return
      }
    } else {
      // no Pointer Events...
      if (e.matches && observerRecord) {
        // check for any-pointer:coarse which mostly means touchscreen
        observerRecord.isTouch.value = true
        return
      }

      if ((window.TouchEvent || 'ontouchstart' in window) && observerRecord) {
        // last resort - check for exposed touch events API / event handler
        observerRecord.isTouch.value = true
        return
      }
    }

    if (observerRecord) {
      observerRecord.isTouch.value = false
    }
  }
  let unwatch: () => void | undefined
  const timer = setTimeout(() => {
    if (!observer.touchDevice) {
      const mqList = window.matchMedia(`(any-pointer:coarse)`)

      observer.touchDevice = {
        listener: onTouchDevice,
        mqList,
        clients: 0,
        isTouch: ref(mqList.matches)
      } as TouchObservableObj

      mqList.addEventListener('change', onTouchDevice)
    }
    const observerRecord = observer.touchDevice
    if (observerRecord) {
      observerRecord.clients = (observerRecord.clients || 0) + 1

      isTouch.value = observerRecord.isTouch.value

      unwatch = watch(observerRecord.isTouch, () => {
        isTouch.value = observerRecord.isTouch.value
      })
    }
  })

  return {
    isTouch: () => isTouch.value,
    dispose() {
      if (unwatch) {
        unwatch()
        if (observer.touchDevice) {
          observer.touchDevice.clients = (observer.touchDevice.clients || 0) - 1
          if (observer.touchDevice.clients === 0) {
            observer.touchDevice.mqList.removeEventListener('change', observer.touchDevice.listener)
            delete observer.touchDevice
          }
        }
      } else {
        clearTimeout(timer)
      }
    }
  }
}

export const useTouchDevice = () => ({ isTouchDevice: computed(touchObserver().isTouch) })
