<template>
  <div
    v-if="visible"
    class="asf-cookies-notice"
    role="dialog"
    :aria-label="`${$t('accessibility.group.common.cookiesNotice')}`"
  >
    <AsfWrapper class="asf-cookies-notice-wrapper" type="section" tag="div">
      <div class="asf-notice-text-wrapper">
        <p class="asf-notice-text">
          <slot />
          <AsfLink class="asf-notice-link" :link="path" target="_blank">
            {{ linkText }}
          </AsfLink>
        </p>
      </div>

      <div class="asf-button-group">
        <AsfButton
          class="asf-button-outline asf-notice-button asf-notice-accept"
          :aria-label="`${$t('cookiesNotice.acceptAll')}`"
          @click="handleConfirm"
        >
          {{ $t('cookiesNotice.acceptAll') }}
        </AsfButton>
        <AsfButton
          class="asf-button-simple asf-notice-button asf-notice-without-accept text-white"
          :aria-label="`${$t('cookiesNotice.acceptWithout')} ${$t('cookiesNotice.tracking')}`"
          @click="handleConfirmNoTracking"
        >
          {{ $t('cookiesNotice.acceptWithout') }}
          <br />
          {{ $t('cookiesNotice.tracking') }}
        </AsfButton>
      </div>
    </AsfWrapper>
  </div>
</template>
<script lang="ts" setup>
import type { AsfCookiesNoticeProps } from '@ui/types'

defineProps<AsfCookiesNoticeProps>()

const emit = defineEmits(['confirmed:cookie-notice', 'confirmed:cookie-notice-no-tracking'])
const handleConfirm = () => emit('confirmed:cookie-notice')
const handleConfirmNoTracking = () => emit('confirmed:cookie-notice-no-tracking')
</script>
<style lang="postcss">
@import '@components/molecules/CookiesNotice/CookiesNotice.css';
</style>
