<script setup lang="ts">
interface AppFooterProps<T = any> {
  benefits?: Array<T>
  features?: Array<T>
  navigations?: Array<T>
  socials?: Array<T>
}
const props = defineProps<AppFooterProps>()
const { $preferences } = useNuxtApp()
const navigation = computed(() => ({
  componentName: 'FooterNavigation',
  navigations: props.navigations ?? [],
  isFindInStore: $preferences?.findInStoreEnabled
}))
</script>
<template>
  <AsfFooter>
    <template v-if="benefits?.length" #content-benefits="{ classNames }">
      <ContentFooter :content="benefits" :class="classNames" />
    </template>
    <template v-if="features?.length" #content-features>
      <ContentFooter :content="features" />
    </template>
    <template v-if="navigations?.length" #content-navigation>
      <ContentFooter :content="[navigation]" />
    </template>
    <template v-if="socials?.length" #content-socials="{ classNames }">
      <ContentFooter :content="socials" :class="classNames" />
    </template>
  </AsfFooter>
</template>
